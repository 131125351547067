@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .clip-diagonal {
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
  }
  .clip-diagonal-inverse {
    clip-path: polygon(0 70%, 100% 0, 100% 100%, 0 100%);
  }
}
/* Add padding to main or first section */


code {
  
  font-family: 'Poppins', sans-serif; /* Use only Poppins */
  
}



html, body {
  font-family: 'Poppins', sans-serif; /* Use only Poppins */
  color: #ffffff;
  background-color: #0a0d17;
  min-height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */

}



h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}



/* Hero Section */
.hero {
  text-align: center;
  padding: 80px 20px;
  background-color: #0a0d17;
}

.hero h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.4;
}

.hero h1 span {
  color: #007bff;
}

.hero p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #b0b8c2;
}

.hero .btn {
  display: inline-block;
  padding: 12px 24px;
  margin: 15px 10px;
  background-color: #282c34;
  color: #ffffff;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero .btn:hover {
  background-color: #3a3f47;
}

.hero .btn-primary {
  background-color: #007bff;
}

.hero .btn-primary:hover {
  background-color: #0056b3;
}

/* Why DevTrust Section */
.why-devtrust {
  background: linear-gradient(to bottom, #0a0d17, #1c1f26);
  text-align: center;
  padding: 80px 20px;
}

.why-devtrust h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.why-devtrust p {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #b0b8c2;
}

.why-devtrust .features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.why-devtrust .feature {
  text-align: center;
}

.why-devtrust .feature img {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.why-devtrust .feature h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.why-devtrust .feature p {
  margin-top: 10px;
  font-size: 1rem;
  color: #b0b8c2;
}

/* How DevTrust Works Section */
.how-devtrust {
  background-color: #0a0d17;
  text-align: center;
  padding: 80px 20px;
}

.how-devtrust h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.how-devtrust .steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.how-devtrust .step {
  text-align: center;
}

.how-devtrust .step img {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.how-devtrust .step p {
  font-size: 1.1rem;
  color: #b0b8c2;
}

/* Roadmap Section */
.roadmap {
  background: linear-gradient(to bottom, #1c1f26, #282c34);
  text-align: center;
  padding: 80px 20px;
}

.roadmap h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.roadmap .phases {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 50px;
}

.roadmap .phase {
  background-color: #1c1f26;
  padding: 20px;
  border-radius: 10px;
  text-align: left;
}

.roadmap .phase h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.roadmap .phase ul {
  margin-top: 15px;
  list-style: inside disc;
}

.roadmap .phase ul li {
  margin-top: 5px;
  color: #b0b8c2;
}

/* FAQ Section */
.faq {
  background-color: #0a0d17;
  text-align: center;
  padding: 80px 20px;
}

.faq h2 {
  font-size: 2.5rem;
  font-weight: 700;
}

.faq .questions {
  margin-top: 50px;
}

.faq .question {
  margin-bottom: 20px;
  text-align: left;
  padding: 15px 20px;
  background-color: #1c1f26;
  border-radius: 6px;
}

.faq .question h4 {
  font-size: 1.2rem;
  font-weight: 600;
}

.faq .question p {
  font-size: 1rem;
  color: #b0b8c2;
  margin-top: 10px;
}

/* Call to Action Section */
.call-to-action {
  background: linear-gradient(to right, #007bff, #0056b3);
  text-align: center;
  padding: 60px 20px;
  color: #ffffff;
}

.call-to-action h3 {
  font-size: 2rem;
  font-weight: 700;
}

.call-to-action p {
  font-size: 1.2rem;
  margin-top: 15px;
}

.call-to-action form {
  margin-top: 20px;
}

.call-to-action input[type='email'] {
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
}

.call-to-action button {
  margin-left: 10px;
  padding: 12px 20px;
  border: none;
  background-color: #ffffff;
  color: #007bff;
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
}

.call-to-action button:hover {
  background-color: #f0f0f0;
}
/* Footer.css */

.footer {
  background: #000;
  padding: 30px 0;
  font-family: 'Play', sans-serif;
  text-align: center;
  color: gray;
  font-size: 0.8em; /* Ensure consistent font size */
}

.footer .row {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer .row a {
  text-decoration: none;
  color: gray;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  margin: 0 10px;
  font-size: 2em;
}

.footer .row a:hover {
  color: #fff;
}

.footer .row ul {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px 0;
  font-size: 4em;
}

.footer .row ul li {
  margin: 0 15px;
  
}

.footer .row ul li a {
  color: gray;
  text-decoration: none;
}

.footer .row ul li a:hover {
  color: #fff;
}

.footer .row:last-child {
  font-size: 0.9em; /* Matches the copyright text size */
  color: gray;
}

@media (max-width: 720px) {
  .footer {
    padding: 20px;
  }

  .footer .row {
    flex-direction: column;
    text-align: center;
  }

  .footer .row ul {
    flex-direction: column;
    align-items: center;
  }

  .footer .row ul li {
    margin: 10px 0;
  }
}
